.App {
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  min-height: 500vh;
  width: 100vw;
  background-color: #ededed;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.snap-point {
  scroll-snap-align: start;
}

.hstack {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.vstack {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.nowrap {
  flex-wrap: nowrap;
}

.flex-start {
  align-items: center;
  justify-content: flex-start;
}

.space-between {
  align-items: center;
  justify-content: space-between;
}

.menu {
  height: 70px;
  width: 85%;
  padding: 10px;
}

.card {
  flex: 1;
  max-width: 50vw;
  min-width: 300px;
  max-height: 200px;
  background-color: #212121;
  border-radius: 10px;
  box-shadow: 0 0 2px 4px rgba(0,0,0,0.2);
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 30px;
}

.hero {
  max-width: 85%;
  min-height: 300px;
  background-color: #212121;
  border-radius: 10px;
  align-items: flex-end;
  justify-content: space-evenly;
  padding: 20px;
  margin-bottom: 30px;
}

.profile {
  width: 40px;
  height: 40px;
  background-color: #eee;
  border-radius: 20px;
  margin-top: 30px;
  margin-right: 10px;
}

.avatar {
  max-width: 40vw;
  margin-bottom: -20px;
}

.intro {
  width: 100%;
  min-width: 200px;
  text-align: end;
}

.flex-end {
  align-items: flex-end;
  justify-content: flex-end;
}

.flex-start {
  align-items: center;
  justify-content: flex-start;
}

.badge {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 5px;
  background-color: #212121;
  box-shadow: 0 0 2px 4px rgba(0,0,0,0.2);
}

.buttons {
  width: 100%;
}

.button-primary {
  padding: 14px 30px;
  margin: 10px;
  background-color: #a0653d;
  border-radius: 10px;
  color: #eee;
  text-decoration: none;
  box-shadow: 0 0 2px 4px rgba(0,0,0,0.2);
  font-weight: bold;
}

h1, h2, h3, p {
  color: #eee;
  text-align: start;
  max-width: 40vw;
}

h1 {
  font-size: 3em;

}

h2 {
  margin-top: 30px;
  margin-bottom: 0px;
}

h3 {
  margin-top: 0;
  font-weight: 600;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #bbb;
  margin-bottom: 10px;
}

p {
  margin-top: 0px;
  max-width: 400px;
}

hr {
  max-width: 100px;
  min-width: 50px;
  margin-top: 20px;
}

html, body {
  display: flex;
  flex-flow: row nowrap;
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    padding-top: 100px;
    background: #3c31dd;
}

.waves {
  background-image: url('./waves.svg');
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}